import { useQuery } from 'react-query'
import { twMerge } from 'tailwind-merge'
import { Spinner } from '@newstore/nom-core-components'
import { Popover } from '@headlessui/react'
import { InfoPanel } from '../InfoPanel'
import classes from './storeIcon.module.css'

export const StoreIcon = ({ store, tenant, stage, accessToken }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['store-config'],
    queryFn: async () => {
      if (!store?.store_id) return
      const request = await fetch(`https://${tenant}.${stage}.newstore.net/api/v1/associate-app/config/${store.store_id}`)
      return request.json()
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false
})

  if (!store || isLoading) return <Spinner />

  return (
    <Popover>
      <Popover.Button>
        <div className='grid justify-center w-8 h-8 p-0.5 rounded bg-white'>
          <img
            src={`/logos/${tenant}.png`}
            alt='Store location'
            className='place-self-center w-5'
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/logos/newstore.png";
            }}
          />
        </div>
      </Popover.Button>
      <Popover.Panel
        data-popper-placement='bottom'
        className={twMerge(
          'absolute rounded border border-gray-200 bg-white p-4 z-50',
          classes.popover
        )}
      >
        <InfoPanel store={store} config={data} tenant={tenant} stage={stage} accessToken={accessToken} />
      </Popover.Panel>
    </Popover>
  )
}
