import { SecureRoute, Security, LoginCallback } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Route, useHistory } from 'react-router-dom'
import { StoreLocator } from './pages/StoreLocator'
import './App.css';

const oktaAuth = new OktaAuth({
  issuer: 'https://newstore-sso.okta.com',
  clientId: '0oa10aounh18KwwEG358',
  redirectUri: window.location.origin + '/login/callback',
});

const App = () => {
  const history = useHistory()

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SecureRoute path='/' exact={true} component={StoreLocator} />
      <Route path='/login/callback' component={LoginCallback} />
    </Security>
  )
}

export default App;
