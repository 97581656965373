export const PromisedTenantData = [
  {
    "status": "fulfilled",
    "value": [
      {
        "active_status": true,
        "label": "FRAME MIAMI",
        "store_id": "293008",
        "physical_address": {
          "address_line_1": "173 NE 40TH AVE",
          "country_code": "US",
          "address_line_2": "",
          "province": null,
          "state": "FL",
          "zip_code": "33126",
          "city": "Miami",
          "latitude": 25.8139204767898,
          "longitude": -80.1918668305337
        },
        "phone_number": "(786) 870-4496",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME DRAYCOTT",
        "store_id": "293017",
        "physical_address": {
          "address_line_1": "114 Draycott Avenue",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": "Greater London",
          "zip_code": "SW3 3AE",
          "city": "London",
          "latitude": 51.4938911333083,
          "longitude": -0.167822572498077
        },
        "phone_number": "+442081325309",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME ASPEN",
        "store_id": "293004",
        "physical_address": {
          "address_line_1": "535 E. Hyman Ave. Unit 103",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CO",
          "zip_code": "81611",
          "city": "Aspen",
          "latitude": 39.18858783,
          "longitude": -106.8176919
        },
        "phone_number": "(970) 300-2928",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME PALM BEACH",
        "store_id": "293016",
        "physical_address": {
          "address_line_1": "340 Royal Poinciana Way",
          "country_code": "US",
          "address_line_2": "Suite M325",
          "province": null,
          "state": "FL",
          "zip_code": "33480",
          "city": "Palm Beach",
          "latitude": 29.958443,
          "longitude": -90.064411
        },
        "phone_number": "(561) 363-5575",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME DALLAS",
        "store_id": "293005",
        "physical_address": {
          "address_line_1": "6 Highland Park Village",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "TX",
          "zip_code": "75205",
          "city": "Dallas",
          "latitude": 32.83539388,
          "longitude": -96.80497576
        },
        "phone_number": "(469) 620-0871",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME GROVE",
        "store_id": "293009",
        "physical_address": {
          "address_line_1": "189 The Grove Drive K-20",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CA",
          "zip_code": "90036",
          "city": "Los Angeles",
          "latitude": 34.07238708,
          "longitude": -118.3570181
        },
        "phone_number": "(323) 879-6116",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME MADISON",
        "store_id": "293014",
        "physical_address": {
          "address_line_1": "900 Madison Avenue",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "10021",
          "city": "New York",
          "latitude": 40.77258625,
          "longitude": -73.96518493
        },
        "phone_number": "(646) 568-4706",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME SOHO",
        "store_id": "293002",
        "physical_address": {
          "address_line_1": "51 Greene St",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "10013",
          "city": "New York",
          "latitude": 40.7225217583446,
          "longitude": -74.0018416574372
        },
        "phone_number": "(646) 693-5361",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME FILLMORE ST",
        "store_id": "293003",
        "physical_address": {
          "address_line_1": "2142 Fillmore Street",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CA",
          "zip_code": "94115",
          "city": "San Francisco",
          "latitude": 37.7899665021717,
          "longitude": -122.433794572848
        },
        "phone_number": "(415) 799-1337",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME MELROSE",
        "store_id": "293001",
        "physical_address": {
          "address_line_1": "8467 Melrose Place",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CA",
          "zip_code": "90069",
          "city": "Los Angeles",
          "latitude": 34.08401787,
          "longitude": -118.3755439
        },
        "phone_number": "3104642270",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME GREENWICH",
        "store_id": "293006",
        "physical_address": {
          "address_line_1": "250 Greenwich Ave",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CT",
          "zip_code": "06830",
          "city": "Greenwich",
          "latitude": 41.02664105,
          "longitude": -73.62621014
        },
        "phone_number": "(203) 742-7719",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME PALISADES",
        "store_id": "293007",
        "physical_address": {
          "address_line_1": "1028 N. Swarthmore Ave #3-102",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CA",
          "zip_code": "90272",
          "city": "Pacific Palisades",
          "latitude": 34.04826639,
          "longitude": -118.5249604
        },
        "phone_number": "(424) 652-5576",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME MEAT PACKING",
        "store_id": "293012",
        "physical_address": {
          "address_line_1": "64 Gansevoort Street, 1st Floor & Cellar",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "10014",
          "city": "New York",
          "latitude": 40.73944418,
          "longitude": -74.00741613
        },
        "phone_number": "(917) 512-7405",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME BOSTON",
        "store_id": "293013",
        "physical_address": {
          "address_line_1": "156 Newbury Street 1st Floor",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "MA",
          "zip_code": "02116",
          "city": "Boston",
          "latitude": 42.35067712,
          "longitude": -71.0782366
        },
        "phone_number": "(857) 496-5810",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME HOUSTON",
        "store_id": "293010",
        "physical_address": {
          "address_line_1": "4444 Westheimer Rd",
          "country_code": "US",
          "address_line_2": "Suite D110",
          "province": null,
          "state": "TX",
          "zip_code": "77027",
          "city": "Houston",
          "latitude": 29.74209214,
          "longitude": -95.45300742
        },
        "phone_number": "(281) 456-4396",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME AUSTIN",
        "store_id": "293011",
        "physical_address": {
          "address_line_1": "1011 S Congress Ave, Suite 110",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "TX",
          "zip_code": "78704",
          "city": "Austin",
          "latitude": 30.2534375,
          "longitude": -97.74801459
        },
        "phone_number": "(512) 900-7684",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "[TEST]FRAME MANHASSET",
        "store_id": "293015",
        "physical_address": {
          "address_line_1": "2054 Northern Bld",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "11030",
          "city": "Manhasset",
          "latitude": 40.79600522,
          "longitude": -73.67157397
        },
        "phone_number": "(516) 464-0526",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME GEORGETOWN",
        "store_id": "293019",
        "physical_address": {
          "address_line_1": "3105 M Street NW",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "DC",
          "zip_code": "20007",
          "city": "Georgetown",
          "latitude": 38.90534,
          "longitude": -77.06142
        },
        "phone_number": "(202) 769-3353",
        "image_url": null,
        "tenant": "frame"
      },
      {
        "active_status": true,
        "label": "FRAME MARYLEBONE",
        "store_id": "293018",
        "physical_address": {
          "address_line_1": "93b-94 Marylebone High Street",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": "Greater London",
          "zip_code": "W1U 4RG",
          "city": "London",
          "latitude": 51.51976,
          "longitude": -0.15196
        },
        "phone_number": "+442081325309",
        "image_url": null,
        "tenant": "frame"
      }
    ]
  },
  {
    "status": "fulfilled",
    "value": [
      {
        "active_status": true,
        "label": "GANNI Kings Road",
        "store_id": "KING",
        "physical_address": {
          "address_line_1": "94 Kings Road",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "SW3 4TZ",
          "city": "Chelsea/London",
          "latitude": 51.490964144371375,
          "longitude": -0.16175189501881906
        },
        "phone_number": "+44 7397 278120",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Bremerholm",
        "store_id": "BREM",
        "physical_address": {
          "address_line_1": "Bremerholm 4",
          "country_code": "DK",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "1069",
          "city": "Copenhagen",
          "latitude": 55.679123,
          "longitude": 12.5803459
        },
        "phone_number": "+45 20 88 53 11",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI South Coast Plaza",
        "store_id": "SOCO",
        "physical_address": {
          "address_line_1": "3333 Bristol",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CA",
          "zip_code": "92626",
          "city": "Costa Mesa",
          "latitude": 33.6912812,
          "longitude": -117.8912718
        },
        "phone_number": "+1 949-612-9300",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Århus",
        "store_id": "AARH",
        "physical_address": {
          "address_line_1": "Guldsmedegade 26",
          "country_code": "DK",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "8000",
          "city": "Århus C",
          "latitude": 56.159322,
          "longitude": 10.2056263
        },
        "phone_number": "0045 2611 7745",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": false,
        "label": "GANNI København K - PERMANENTLY CLOSED",
        "store_id": "STRG",
        "physical_address": {
          "address_line_1": "Store Regnegade 12",
          "country_code": "DK",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "1110",
          "city": "København K",
          "latitude": 55.681497,
          "longitude": 12.5798733
        },
        "phone_number": "0045 20 88 53 11",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Waterfront",
        "store_id": "WATF",
        "physical_address": {
          "address_line_1": "Tuborg Havnevej 4-8",
          "country_code": "DK",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "2900",
          "city": "Hellerup",
          "latitude": 55.7257511,
          "longitude": 12.5765809
        },
        "phone_number": "0045 2092 8900",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Østerbro",
        "store_id": "OEBR",
        "physical_address": {
          "address_line_1": "Østerbrogade 50",
          "country_code": "DK",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "2100",
          "city": "København Ø",
          "latitude": 55.696968,
          "longitude": 12.5775484
        },
        "phone_number": "0045 2220 0285",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Postmodern",
        "store_id": "POST",
        "physical_address": {
          "address_line_1": "Overgaden Oven Vandet 40",
          "country_code": "DK",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "1415",
          "city": "København K",
          "latitude": 55.6726716,
          "longitude": 12.5895607
        },
        "phone_number": "+45 28 35 55 56",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Stockholm",
        "store_id": "NORR",
        "physical_address": {
          "address_line_1": "Norrlandsgatan 11",
          "country_code": "SE",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "111 43",
          "city": "Stockholm",
          "latitude": 59.3339506,
          "longitude": 18.068939
        },
        "phone_number": "(+46) 73 507 04 07",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Beak Street",
        "store_id": "BEAK",
        "physical_address": {
          "address_line_1": "36 Beak Street",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "W1F 9RF",
          "city": "London",
          "latitude": 51.5122793,
          "longitude": -0.1397725
        },
        "phone_number": "+44 7397277172",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Mercer Street",
        "store_id": "MERC",
        "physical_address": {
          "address_line_1": "40 Mercer Street",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "10013",
          "city": "New York",
          "latitude": 40.7222541,
          "longitude": -74.0008214
        },
        "phone_number": "+1 646 989 0913",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Melrose Avenue",
        "store_id": "MELR",
        "physical_address": {
          "address_line_1": "9004 Melrose Avenue",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CA",
          "zip_code": "90069",
          "city": "West Hollywood",
          "latitude": 34.0807605,
          "longitude": -118.3901252
        },
        "phone_number": "+1 323 807 0965",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Downtown LA",
        "store_id": "DTLA",
        "physical_address": {
          "address_line_1": "860 S Broadway",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CA",
          "zip_code": "90014",
          "city": "Los Angeles",
          "latitude": 34.0423572,
          "longitude": -118.2579208
        },
        "phone_number": "+1 3238063533",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Miami Design District",
        "store_id": "MIAM",
        "physical_address": {
          "address_line_1": "3906 NE 1st Avenue",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "Florida",
          "zip_code": "33137",
          "city": "Miami",
          "latitude": 25.8130384,
          "longitude": -80.1935587
        },
        "phone_number": "+1 786-676-8139",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Floral Street",
        "store_id": "FLOR",
        "physical_address": {
          "address_line_1": "15 Floral Street",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "WC2E 9DH",
          "city": "London",
          "latitude": 51.5119838,
          "longitude": -0.1360816
        },
        "phone_number": "+44 7397274068",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI 29 Juillet",
        "store_id": "FR-PA-JUIL",
        "physical_address": {
          "address_line_1": "1 Rue Du 29 Juillet",
          "country_code": "FR",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "75001",
          "city": "Paris",
          "latitude": 48.864912702986494,
          "longitude": 2.33018597138644
        },
        "phone_number": "+33 6 77 21 58 22",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Williamsburg",
        "store_id": "WILL",
        "physical_address": {
          "address_line_1": "113 North 7th Street",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "11249",
          "city": "Brooklyn",
          "latitude": 40.7190188,
          "longitude": -73.9614558
        },
        "phone_number": "+1 929 210 2118 ",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Marais",
        "store_id": "FR-PA-MARA",
        "physical_address": {
          "address_line_1": "118, Rue Vieille du Temple",
          "country_code": "FR",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "75003",
          "city": "Paris",
          "latitude": 48.861471195483944,
          "longitude": 2.3636119140517473
        },
        "phone_number": "+33 7 89 07 38 08",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Cabazon",
        "store_id": "US-CA-CABA",
        "physical_address": {
          "address_line_1": "Desert Hills Premium Outlets 48650 Seminole Dr. Suite 134",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "CA",
          "zip_code": "92230",
          "city": "Cabazon",
          "latitude": 33.92921203117294,
          "longitude": -116.81519474394419
        },
        "phone_number": "+1 442 300 6044",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Austin",
        "store_id": "US-TX-AUST",
        "physical_address": {
          "address_line_1": "1200 South Congress Avenue",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "TX",
          "zip_code": "78704",
          "city": "Austin",
          "latitude": 30.252428183636255,
          "longitude": -97.74904471534526
        },
        "phone_number": "+1 737 308 7305",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Yorkdale",
        "store_id": "CA-TO-YORK",
        "physical_address": {
          "address_line_1": "3401 Dufferin Street (Unit: CRU 226A)",
          "country_code": "CA",
          "address_line_2": null,
          "province": null,
          "state": "ON",
          "zip_code": "M6A 2T9",
          "city": "Toronto",
          "latitude": 43.72529435915667,
          "longitude": -79.45279797315474
        },
        "phone_number": "+14373353664",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Woodbury",
        "store_id": "WOOD",
        "physical_address": {
          "address_line_1": "498 Red Apple Court #324",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "10917",
          "city": "Central Valley",
          "latitude": 41.316421,
          "longitude": -74.125714
        },
        "phone_number": "+1 8453253721",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Amsterdam",
        "store_id": "AMST",
        "physical_address": {
          "address_line_1": "RUNSTRAAT 17",
          "country_code": "NL",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "1016 GJ",
          "city": "AMSTERDAM",
          "latitude": 52.36900149641662,
          "longitude": 4.883400583292944
        },
        "phone_number": "+31 625 494 443",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Hegdehaugsveien",
        "store_id": "NO-OS-HEGD",
        "physical_address": {
          "address_line_1": "Hegdehaugsveien 30",
          "country_code": "NO",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "0258",
          "city": "Oslo",
          "latitude": 59.92163321017727,
          "longitude": 10.726843427835695
        },
        "phone_number": "+47 907 92 378",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Sandvika",
        "store_id": "NO-OS-SAND",
        "physical_address": {
          "address_line_1": "Storsenter Sandviksveien 176",
          "country_code": "NO",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "1338",
          "city": "Sandvika",
          "latitude": 59.888303214996306,
          "longitude": 10.520758827385503
        },
        "phone_number": "+47 908 01 797",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": false,
        "label": "GANNI Kristiansand - PERMANENTLY CLOSED",
        "store_id": "NO-KR-MARK",
        "physical_address": {
          "address_line_1": "Markens gate 29",
          "country_code": "NO",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "4611",
          "city": "Kristiansand",
          "latitude": 58.146149753042025,
          "longitude": 7.991992369311988
        },
        "phone_number": "+47 416 85 050",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Bergen",
        "store_id": "NO-BE-STRA",
        "physical_address": {
          "address_line_1": "Strandgaten 21",
          "country_code": "NO",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "5013",
          "city": "Bergen",
          "latitude": 60.39475428743129,
          "longitude": 5.321473414354475
        },
        "phone_number": "+47 489 48 888",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Stavanger",
        "store_id": "NO-ST-NYGA",
        "physical_address": {
          "address_line_1": "Nygata 5",
          "country_code": "NO",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "4006",
          "city": "Stavanger",
          "latitude": 58.970778821149416,
          "longitude": 5.734131341306262
        },
        "phone_number": "+47 908 14 565",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Prinsens gate",
        "store_id": "NO-OS-PRIN",
        "physical_address": {
          "address_line_1": "Prinsens gate 23",
          "country_code": "NO",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "0157",
          "city": "Oslo",
          "latitude": 59.912053070285715,
          "longitude": 10.740755983655442
        },
        "phone_number": "+47 414 09 555",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Frederiksberg",
        "store_id": "GLKV",
        "physical_address": {
          "address_line_1": "Gl. Kongevej 82",
          "country_code": "DK",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "1850",
          "city": "Frederiksberg C",
          "latitude": 55.6746414,
          "longitude": 12.547662
        },
        "phone_number": "0045 41 78 47 80",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Postmodern Oslo",
        "store_id": "NO-OS-POST",
        "physical_address": {
          "address_line_1": "Markveien 53",
          "country_code": "NO",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "0554",
          "city": "Oslo",
          "latitude": 59.92089650855348,
          "longitude": 10.75726506425163
        },
        "phone_number": " +47 905 92 565",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Houston",
        "store_id": "US-TX-HOUS",
        "physical_address": {
          "address_line_1": "888 Westheimer Rd.",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "TX",
          "zip_code": "77006",
          "city": "Houston",
          "latitude": 29.7450187,
          "longitude": -95.3902692
        },
        "phone_number": "+1 281 702 3567",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI North Park",
        "store_id": "US-TX-NORT",
        "physical_address": {
          "address_line_1": "8687 NORTH CENTRAL EXPRESSWAY, SPACE M2-1122",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "TX",
          "zip_code": "75225 ",
          "city": "Dallas",
          "latitude": 32.8687289,
          "longitude": -96.7718635
        },
        "phone_number": "+1 (469)460-5399",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Aventura",
        "store_id": "US-FL-AVEN",
        "physical_address": {
          "address_line_1": "19501 BISCAYNE BLVD, UNIT 803",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "FL",
          "zip_code": "33180",
          "city": "Aventura",
          "latitude": 25.9559431,
          "longitude": -80.142183
        },
        "phone_number": "+1 (786)510-1479",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Grosse Bleichen",
        "store_id": "DE-HH-GROS",
        "physical_address": {
          "address_line_1": "Grosse Bleichen 23-27",
          "country_code": "DE",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "20354",
          "city": "Hamburg",
          "latitude": 53.5523818,
          "longitude": 9.9893043
        },
        "phone_number": "+491736212017",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI CPH Airport",
        "store_id": "DK-CPH-AIR",
        "physical_address": {
          "address_line_1": "Cph Airport, Lufthavnsboulevarden 6",
          "country_code": "DK",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "2770",
          "city": "Kastrup",
          "latitude": 55.6287096,
          "longitude": 12.6459329
        },
        "phone_number": "+45 31 26 20 00",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Rochstrasse",
        "store_id": "DE-BE-ROCH",
        "physical_address": {
          "address_line_1": "Rochstrasse 1",
          "country_code": "DE",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "10178",
          "city": "Berlin",
          "latitude": 52.5243826,
          "longitude": 13.407482
        },
        "phone_number": "+49 173 6211917",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI POP UP UK",
        "store_id": "UK-UK-POP",
        "physical_address": {
          "address_line_1": "Bicester Village, 50 Pingle Drive",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "OX26 6WD",
          "city": "Bicester",
          "latitude": 51.8919589,
          "longitude": -1.1555868
        },
        "phone_number": null,
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI FlatIron",
        "store_id": "US-NY-FLAT",
        "physical_address": {
          "address_line_1": "150 Fifth Avenue",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "10011",
          "city": "New York",
          "latitude": 40.7395932,
          "longitude": -73.9914
        },
        "phone_number": "+1 646 745 4093",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Bleecker",
        "store_id": "US-NY-BLEE",
        "physical_address": {
          "address_line_1": "382 Bleecker Street",
          "country_code": "US",
          "address_line_2": null,
          "province": null,
          "state": "NY",
          "zip_code": "10014",
          "city": "New York",
          "latitude": 40.7351281,
          "longitude": -74.0050285
        },
        "phone_number": "+1 347 276 1298",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI POP-UP CA",
        "store_id": "CA-CA-POP",
        "physical_address": {
          "address_line_1": "Toronto Premium Outlets, 13850 Steeles Avenue, #849",
          "country_code": "CA",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "L7G 0J1",
          "city": "Halton Hill",
          "latitude": 43.5760541,
          "longitude": -79.8299187
        },
        "phone_number": null,
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI POP-UP FR",
        "store_id": "FR-FR-POP",
        "physical_address": {
          "address_line_1": "La Vallée Village, 3 Cours de la Garonne, Emplacement 31 “Le Salon“",
          "country_code": "FR",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "77700",
          "city": "Serris",
          "latitude": 48.8532642,
          "longitude": 2.7828847
        },
        "phone_number": null,
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Postmodern Bicester",
        "store_id": "UK-BI-POST",
        "physical_address": {
          "address_line_1": "Unit 122, 50 Pingle Drive",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "OX26 6WD",
          "city": "Bicester",
          "latitude": 51.8934844,
          "longitude": -1.1585168
        },
        "phone_number": "+447361901985",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": true,
        "label": "GANNI Marylebone High Street",
        "store_id": "UK-LON-MAR",
        "physical_address": {
          "address_line_1": "69 Marylebone High Street",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": "",
          "zip_code": "W1U 5JJ",
          "city": "London",
          "latitude": 51.5216406,
          "longitude": -0.1519374
        },
        "phone_number": "+447737271628",
        "image_url": null,
        "tenant": "ganni"
      },
      {
        "active_status": false,
        "label": "deprecated",
        "store_id": "CC-MAN",
        "physical_address": {
          "address_line_1": "deprecated",
          "country_code": "GB",
          "address_line_2": null,
          "province": null,
          "state": null,
          "zip_code": "deprecated",
          "city": "deprecated",
          "latitude": null,
          "longitude": null
        },
        "phone_number": "00000",
        "image_url": null,
        "tenant": "ganni"
      }
    ]
  }
]
