import {useRef, useState} from "react"
import {Button, Dialog, Input} from '@newstore/nom-core-components'
import {useQuery} from 'react-query'
export const LoginDialog = ({ setAccessToken }) => {
  const acceptButton = useRef();
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState()

  const fetchToken = async () => {
    const res = await fetch('https://dodici-demo.p.newstore.net/v0/token', {
      method: 'POST',
      body: `grant_type=password&username=${email}&password=${password}`
    })
    const token = (await res.json()).access_token
    if (token) {
      localStorage.setItem('NewStoreLocator-accessToken', token)
      setAccessToken(token)
    }
  }

  const { refetch } = useQuery({
    queryKey: ['accessToken'],
    queryFn: fetchToken,
    enabled: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })

  return (
    <Dialog title='NOM Admin account' onClose={() => {}} open={true} buttons={
      <>
        <Button key='confirm' variant='primary' className='bg-pink-500' ref={acceptButton} onClick={() => {
          if (email.includes('@newstore.com')) {
            refetch()
          } else {
            setError('The specified email is not valid for this application')
          }
        }}>
          Login
        </Button>
      </>
    }>
      <p className='mb-8 max-w-[300px] text-justify'>
        In order to use this application, you are required to provide a <b>NOM account
        with Admin privileges</b> and associated with a <b>newstore.com</b> email
      </p>
      <Input
        title='email'
        label='Email'
        type='email'
        placeholder='user@newstore.com'
        onChange={e => setEmail(e.currentTarget.value)}
        error={error}
      />
      <br />
      <Input title='password' label='Password' type='password' onChange={e => setPassword(e.currentTarget.value)} />
    </Dialog>
  )
}
