import { FeaturesPanel } from '../FeaturesPanel'
import dummyStore from '../../assets/dummy-store.png'

export const InfoPanel = ({ store, config, tenant, stage, accessToken }) => {
  return (
    <div className='grid grid-cols-2 gap-4'>
      <div className='flex flex-col leading-normal'>
        <h2 className='mb-4 text-2xl'>{store.label}</h2>
        <p>{store.physical_address.address_line_1}</p>
        <p>{store.physical_address.address_line_2}</p>
        <p>{store.physical_address.city} {store.physical_address.state}</p>
        <p>{store.physical_address.zip_code}</p>
        <br />
        {
          store.phone_number && (
            <>
              <strong>Phone: </strong>
              <a href={`tel:${store.phone_number}`}>{store.phone_number}</a>
            </>
          )
        }
        <br />
        <FeaturesPanel config={config} storeId={store.store_id} tenant={tenant} stage={stage} accessToken={accessToken} />
      </div>
      <img src={store.image_url || dummyStore} alt='Store' className='w-full bg-gray-100' />
    </div>
  )
}
