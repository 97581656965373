import {useState, useEffect, useCallback} from 'react'
import {useQuery} from 'react-query'
import GoogleMapReact from 'google-map-react'
import { createColumnHelper } from '@tanstack/react-table'
import {
  Checkbox,
  Details,
  PageBox,
  PageHeader,
  PageView,
  Select,
  TwoColumnLayout,
  Segment,
  NomTable,
  useNomTable,
  NomTablePlugins,
} from '@newstore/nom-core-components'
import {StoreIcon} from '../../components/StoreIcon'
import {LoginDialog} from "../../components/LoginDialog"
import { PromisedTenantData } from '../../dummy/PromisedTenantData'
import './table.module.css'

const EXCLUDED_TENANT_KEYWORDS = ['sandbox', 'staging', 'team', 'preprod', 'dev', 'demo', 'test', 'camp', 'intqa', 'absolutelabs', 'monitor', 'filson']

const isTokenValid = (accessToken) => {
  if (!accessToken) return false
  try {
    const decoded = JSON.parse(atob(accessToken.split('.')[1]))
    return decoded.exp > Date.now() / 1000
  } catch (e) {
    return false
  }
}

export const StoreLocator = () => {
  const [selectedTenants, setSelectedTenants] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [selectedCountryCodes, setSelectedCountryCodes] = useState([])
  const [selectedStates, setSelectedStates] = useState([])
  const [selectedCities, setSelectedCities] = useState([])
  const [accessToken, setAccessToken] = useState(localStorage.getItem('NewStoreLocator-accessToken'))
  const [selectAll, setSelectAll] = useState('unchecked')
  const [selectedView, setSelectedView] = useState('map')
  const [existingCountryCodes, setExistingCountyCodes] = useState([])
  const [existingStates, setExistingStates] = useState([])
  const [existingCities, setExistingCities] = useState([])
  const [ tableData, setTableData ] = useState([])

  const { data: tenants } = useQuery({
    queryKey: ['tenants'],
    queryFn: async () => {
      const request = await fetch('https://t9oy7nj6z4.execute-api.us-east-1.amazonaws.com/default/api/v1/tenants', {
        headers: { 'x-api-key': 'PtgfveE2QM5OrIa8x0z076WsncNHCo5v2mOdHZJl' }
      })

      return (await request.json())
        .filter(tenant => !EXCLUDED_TENANT_KEYWORDS.some(keyword => tenant.tenant_name.includes(keyword)))
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false
})

  const { data: selectedTenantsData = [] } = useQuery({
    queryKey: ['stores', selectedTenants],
    queryFn: async () => {
      if (accessToken && selectedTenants.length !== 0) {
        let promisedTenantData
        // To avoid API calls when developing, add 'useDummyData: true' to your local storage, so it will use src/dummy/PromisedTenantData.js
        if (!window.localStorage.getItem('useDummyData')) {
          promisedTenantData = await Promise.allSettled(selectedTenants?.map(async (tenant) => {
            const res = await fetch(`https://${tenant}.p.newstore.net/v0/i/stores`, {
              headers: { Authorization: `Bearer ${accessToken}` }
            })
            return (await res.json()).stores
              .map(({ active_status, label, store_id, physical_address, phone_number, image_url }) => ({
                active_status,
                label,
                store_id,
                physical_address,
                phone_number,
                image_url,
                tenant,
              }))
          }))
        } else {
          promisedTenantData = PromisedTenantData
        }
        return promisedTenantData?.filter(result => result.status === 'fulfilled')
          .map(result => result.value)
          .flat()
      } else {
        return []
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false
})

  useEffect(() => {
    if (selectedTenantsData) {
      const countryCodes = []
      const states = []
      const cities = []

      const filtered = selectedTenantsData
        .filter(store => !selectedCountryCodes.length || selectedCountryCodes.includes(store.physical_address.country_code))
        .filter(store => !selectedStates.length || selectedStates.includes(store.physical_address.state))
        .filter(store => !selectedCities.length || selectedCities.includes(store.physical_address.city))

      // Only countries for selected tenants
      selectedTenantsData.forEach(store => {
        if (store.physical_address.country_code && !countryCodes.includes(store.physical_address.country_code)) {
          countryCodes.push(store.physical_address.country_code)
        }
      })

      // Only states for selected countries
      const filteredStates = selectedTenantsData
        .filter(store => !selectedCountryCodes.length || selectedCountryCodes.includes(store.physical_address.country_code))
      filteredStates.forEach(store => {
        if (store.physical_address.state && !states.includes(store.physical_address.state)) {
          states.push(store.physical_address.state)
        }
      })

      // Only cities for selected states
      const filteredCities = filteredStates
        .filter(store => !selectedStates.length || selectedStates.includes(store.physical_address.state))
      filteredCities.forEach(store => {
        if (store.physical_address.city && !cities.includes(store.physical_address.city)) {
          cities.push(store.physical_address.city)
        }
      })

      const tableDataFormatted = filtered?.map(store => {
        const { address_line_1, address_line_2, city, state, zip_code, country_code } = store.physical_address
        const address = `${address_line_1}${address_line_2 ? `, ${address_line_2}` : ''},
          ${city}, ${state} ${zip_code}
          ( ${country_code} )`

        return {
          ...store,
          active_status: store.active_status
            ? <span style={{color: 'green'}}>●</span>
            : <span style={{color: 'red'}}>●</span>,
          physical_address: <a href={`https://maps.google.com/?q=${address}`} target='_blank' rel="noreferrer">{address}</a>,
          phone_number: <a href={`tel:${store.phone_number}`}>{store.phone_number}</a>,
        }
      })

      setExistingCountyCodes(countryCodes)
      setExistingStates(states)
      setExistingCities(cities)
      setFilteredData(filtered)
      setTableData(tableDataFormatted)
    } else {
      setExistingCountyCodes([])
      setExistingStates([])
      setExistingCities([])
      setFilteredData([])
      setTableData([])
    }
  }, [selectedTenantsData, selectedCountryCodes, selectedStates, selectedCities])

  const Search = useCallback(() => <NomTablePlugins.TableSearch placeholder='Search in the table' />, [])

  const createColumn = createColumnHelper().accessor;
  const columns = filteredData.length
    ? Object.keys(filteredData[0])
      .filter(key => key !== 'image_url')
      .map(key => createColumn(key, { header: key }))
    : []

  const table = useNomTable({
    columns,
    data: tableData,
    manualFiltering: false
  })

  return !isTokenValid(accessToken)
    ? <LoginDialog setAccessToken={setAccessToken} />
    : (
      <PageView.Root className='h-screen'>
        <PageHeader.Root>
          <PageHeader.Title>NewStores Locator</PageHeader.Title>
        </PageHeader.Root>

        <TwoColumnLayout.Root>
          <TwoColumnLayout.Side>
            <PageBox.Root>
              <Details.Root>
                <Details.Name>View</Details.Name>
                <Details.Description>
                  <Segment.Root onChange={setSelectedView} value={selectedView}>
                    <Segment.Option key='map' value='map'>Map</Segment.Option>
                    <Segment.Option key='table' value='table'>Table</Segment.Option>
                  </Segment.Root>
                </Details.Description>
                <Details.Name>Tenants</Details.Name>
                <Details.Description>
                  {
                    tenants?.length && (
                      <>
                        <Select
                          isMulti
                          disabled={selectAll === 'checked'}
                          options={
                            tenants?.map(tenant => ({
                              label: tenant.tenant_name,
                              value: tenant.tenant_name,
                            }))
                          }
                          defaultValue={
                            selectedTenants.length && ({
                              label: selectedTenants[0],
                              value: selectedTenants[0],
                            })
                          }
                          onChange={(selection) => {setSelectedTenants(selection.map(({ label }) => label))}}
                        />
                        <Checkbox className='mt-4' disabled status={selectAll} onChange={({ currentTarget }) => {
                          setSelectAll(currentTarget.checked ? 'checked' : 'unchecked')
                          setSelectedTenants(currentTarget.checked ? tenants?.map(({ tenant_name }) => tenant_name) : [])
                        }}>Select All</Checkbox>
                      </>
                    )
                  }
                </Details.Description>
                <Details.Name>Country</Details.Name>
                <Details.Description>
                  <Select
                    isMulti
                    options={ existingCountryCodes.map(countryCode => ({
                      label: countryCode,
                      value: countryCode,
                    }))}
                    onChange={(selection) => {setSelectedCountryCodes(selection.map(({ label }) => label))}}
                  />
                </Details.Description>
                <Details.Name>State</Details.Name>
                <Details.Description>
                  <Select
                    isMulti
                    options={ existingStates.map(state => ({
                      label: state,
                      value: state,
                    }))}
                    onChange={(selection) => {setSelectedStates(selection.map(({ label }) => label))}}
                  />
                </Details.Description>
                <Details.Name>Cities</Details.Name>
                <Details.Description>
                  <Select
                    isMulti
                    options={ existingCities.map(city => ({
                      label: city,
                      value: city,
                    }))}
                    onChange={(selection) => {setSelectedCities(selection.map(({ label }) => label))}}
                  />
                </Details.Description>
              </Details.Root>
            </PageBox.Root>
          </TwoColumnLayout.Side>

          <TwoColumnLayout.Main>
            <PageBox.Root className='h-full'>
              <div className='h-full w-full'>
                {
                  selectedView === 'map'
                  ? (
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyA18IpTpmhlKUdlTMWG9KVRp33HFp8-j_Q' }}
                        defaultCenter={{ lat: 38.914547, lng: -33.746240 }}
                        defaultZoom={0}
                      >
                        {
                          filteredData?.map(store =>
                            store.physical_address.latitude && (
                              <StoreIcon
                                key={`${store.label}-${store.physical_address.latitude}-${store.physical_address.longitude}`}
                                lat={store.physical_address.latitude}
                                lng={store.physical_address.longitude}
                                store={store}
                                tenant={store.tenant}
                                stage='p'
                                accessToken={accessToken}
                              />
                            )
                          )
                        }
                      </GoogleMapReact>
                    )
                    : <NomTable table={table} components={{
                      Search: !!tableData.length ? Search : () => null,
                      Pagination: () => !!tableData.length && <span className='self-center [grid-area:extras]'>{tableData.length} stores matching your criteria</span>,
                      NoDataView: () => !!selectedTenants.length ? 'No stores matching your criteria' : 'Select a tenant to view stores',
                    }}/>
                }
              </div>
            </PageBox.Root>
          </TwoColumnLayout.Main>
        </TwoColumnLayout.Root>
      </PageView.Root>
    )
}

