import { useQuery } from 'react-query'
import { Icon, Spinner } from '@newstore/nom-core-components'
import { faMobileScreenButton, faCartPlus, faFileInvoice, faHousePersonDepart, faHousePersonReturn, faInfinity, faRoute, faBox } from '@newstore/nom-core-components/lib/icons/duotone'

const ENABLED_COLOR = '#D2386EFF'
const DISABLED_COLOR = '#AAAAAA99'

const fetchColorToDisplay = (enabled) => enabled ? ENABLED_COLOR : DISABLED_COLOR

const fetchLocationConfig = async (storeId, tenant, stage, accessToken) => {
  /// TODO: This may be a 404
  const locationRequest = await fetch(`https://${tenant}.${stage}.newstore.net/v0/locations/store/${storeId}`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  })

  if (locationRequest.status === 404) return { id: 'disabled', is_enabled: false }

  return locationRequest.json()
}

const fetchInStorePickupConfig = async (storeId, tenant, stage, accessToken) => {
  const inStorePickupRequest = await fetch(`https://${tenant}.${stage}.newstore.net/v0/d/in_store_pickup_config/stores/${storeId}`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  })

  return inStorePickupRequest.json()
}

const fetchRoutingRuleset = async (tenant, stage, accessToken) => {
  const routingRulesetRequest = await fetch(`https://${tenant}.${stage}.newstore.net/v0/routing/ruleset`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  })

  return routingRulesetRequest.json()
}

const fetchRoutingEnabled = (routingRuleset, locationConfig) =>{
  if (!routingRuleset.location_groups) return false
  Object.keys(routingRuleset.location_groups).some(element =>
    routingRuleset.location_groups[element].includes(locationConfig.id)
  )
}


export const FeaturesPanel = ({ config, storeId, tenant, stage, accessToken }) => {

  const { data: detailedConfig, isLoading: isLoadingDetailedConfig } = useQuery({
    queryKey: ['store-detailed-config', storeId],
    queryFn: async () => {
      const locationConfig = await fetchLocationConfig(storeId, tenant, stage, accessToken)
      const inStorePickupConfig = await fetchInStorePickupConfig(storeId, tenant, stage, accessToken)
      const routingRuleset = await fetchRoutingRuleset(tenant, stage, accessToken)
      const routingEnabled = fetchRoutingEnabled(routingRuleset, locationConfig)

      return {
        isOMSEnabled: locationConfig.is_enabled,
        isBOPISEnabled: !(inStorePickupConfig?.error_code === 'config_not_found'),
        isBORISEnabled: true, // Hardcoded
        endlessAisleEnabled: !(inStorePickupConfig?.error_code === 'config_not_found'), // Endless Aisle is turned on by default as soon as there are locations setup which are able to fulfil and there is a config to allow shipping orders
        fulfilmentEnabled: locationConfig.is_enabled && routingEnabled,
        routingEnabled,
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false
})

  if (!detailedConfig || isLoadingDetailedConfig) return <Spinner />

  return (
    <div className='my-4 grid grid-cols-4 grid-rows-2 gap-4'>
      <div className='flex flex-col items-center' key='POS'>
        <Icon icon={faMobileScreenButton} color={fetchColorToDisplay(!config.ui_configurations.hide_selling)} className='mb-2 h-8 w-8' />
        <span className='block w-full text-center text-[8px]'>POS</span>
      </div>

      <div className='flex flex-col items-center' key='OMS'>
        <Icon icon={faFileInvoice} color={fetchColorToDisplay(detailedConfig.isOMSEnabled)} className='mb-2 h-8 w-8' />
        <span className='block w-full text-center text-[8px]'>OMS</span>
      </div>

      <div className='flex flex-col items-center' key='BOPIS'>
        <Icon icon={faHousePersonDepart} color={fetchColorToDisplay(detailedConfig.isBOPISEnabled)} className='mb-2 h-8 w-8' />
        <span className='block w-full text-center text-[8px]'>BOPIS</span>
      </div>

      <div className='flex flex-col items-center' key='BORIS'>
        <Icon icon={faHousePersonReturn} color={fetchColorToDisplay(detailedConfig.isBORISEnabled)} className='mb-2 h-8 w-8' />
        <span className='block w-full text-center text-[8px]'>BORIS</span>
      </div>

      <div className='flex flex-col items-center' key='EA'>
        <Icon icon={faInfinity} color={fetchColorToDisplay(detailedConfig.endlessAisleEnabled)} className='mb-2 h-8 w-8' />
        <span className='block w-full text-center text-[8px]'>Endless Aisle</span>
      </div>

      <div className='flex flex-col items-center' key='MixedCart'>
        <Icon icon={faCartPlus} color={fetchColorToDisplay(config.capabilities.mixed_cart)} className='mb-2 h-8 w-8' />
        <span className='block w-full text-center text-[8px]'>Mixed Cart</span>
      </div>

      <div className='flex flex-col items-center' key='Routing'>
        <Icon icon={faRoute} color={fetchColorToDisplay(detailedConfig.routingEnabled)} className='mb-2 h-8 w-8' />
        <span className='block w-full text-center text-[8px]'>Routing</span>
      </div>

      <div className='flex flex-col items-center' key='SF'>
        <Icon icon={faBox} color={fetchColorToDisplay(detailedConfig.fulfilmentEnabled)} className='mb-2 h-8 w-8' />
        <span className='block w-full text-center text-[8px]'>Fulfillment</span>
      </div>
    </div>
  )
}
